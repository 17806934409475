* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: NeueMontreal;
  src: url("neue-montreal-regular.c007ba7d.woff") format("woff"), url("neue-montreal-regular.77edb420.woff2") format("woff2");
  font-display: swap;
}

body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: NeueMontreal, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  overflow: hidden;
}

html {
  font-size: .694444vw;
}

@media screen and (max-width: 1023px) {
  html {
    font-size: 2.41546vw;
  }
}

canvas {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

a {
  color: #fff;
}

nav ul {
  grid-template-columns: repeat(4, 1fr);
  margin-top: 4rem;
  margin-left: 50%;
  font-size: clamp(14px, 1.4rem, 21px);
  display: grid;
}

@media screen and (max-width: 1023px) {
  nav ul {
    grid-template-columns: 1fr;
    gap: 1.6rem;
    margin-left: 4rem;
  }
}

li {
  list-style-type: none;
}

li a {
  opacity: 1;
  text-decoration: none;
  transition: opacity 1s cubic-bezier(.22, 1, .36, 1);
}

li a:hover {
  opacity: .6;
}

.main {
  width: 50%;
  padding-left: 8rem;
  position: absolute;
  top: 50%;
  left: 0;
}

@media screen and (max-width: 1023px) {
  .main {
    width: 100%;
    padding-left: 4rem;
    top: auto;
    bottom: 4rem;
  }
}

h1 {
  margin-bottom: .8rem;
  font-size: clamp(64px, 6.4rem, 124px);
  font-weight: 400;
  line-height: 1;
}

@media screen and (max-width: 1023px) {
  h1 {
    font-size: clamp(32px, 4rem, 80px);
  }
}

p {
  max-width: 35rem;
  font-size: clamp(18px, 2.4rem, 40px);
  font-weight: 400;
  line-height: 1.2;
}

@media screen and (max-width: 1023px) {
  p {
    max-width: 24rem;
    font-size: clamp(16px, 1.8rem, 24px);
  }
}

p:last-child {
  margin-top: 1.6rem;
}

p a {
  text-decoration: none;
  position: relative;
}

p a:before {
  content: "";
  width: 100%;
  height: 3px;
  transform-origin: 0;
  background-color: #fff;
  transition: transform 1s cubic-bezier(.22, 1, .36, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(1);
}

@media screen and (max-width: 1023px) {
  p a:before {
    height: 1px;
  }
}

p a:hover:before {
  transform-origin: 100%;
  transform: scaleX(0);
}

.preloader {
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 1;
  background-color: #02001a;
  transition: opacity 1s cubic-bezier(.22, 1, .36, 1) .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.preloader.--hidden {
  pointer-events: none;
  opacity: 0;
}

/*# sourceMappingURL=index.5db22eee.css.map */
