$ease-out: cubic-bezier(0.22, 1, 0.36, 1);

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

@font-face {
    font-family: "NeueMontreal";
    src: url("./fonts/neue-montreal-regular.woff") format("woff"),
         url("./fonts/neue-montreal-regular.woff2") format("woff2");
    font-display: swap;
}
    

body,
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    padding: 0;
    margin: 0;

    font-family: "NeueMontreal", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    color: #fff;
}

html {
    font-size: calc(100vw / 1440 * 10);
    
    @media screen and (max-width: 1023px) {
        font-size: calc(100vw / 414 * 10);
    }
}

canvas {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

a {
    color: #fff;
}

nav ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 4rem;
    margin-left: 50%;

    // font-size: 1.4rem;
    font-size: clamp(14px, 1.4rem, 21px);

    @media screen and (max-width: 1023px) {
        grid-template-columns: 1fr;
        gap: 1.6rem;
        margin-left: 4rem;
    }

    
}

li {
    list-style-type: none;

    a {
        text-decoration: none;
        opacity: 1;

        transition: opacity 1s $ease-out;

        &:hover {
            opacity: 0.6;
        }
    }
}

.main {
    width: 50%;
    padding-left: 8rem;

    position: absolute;
    left: 0;
    top: 50%;

    @media screen and (max-width: 1023px) {
        width: 100%;
        padding-left: 4rem;
        bottom: 4rem;
        top: auto;
    }
}

h1 {
    // font-size: 6.4rem;
    font-size: clamp(64px, 6.4rem, 124px);
    font-weight: 400;
    margin-bottom: 0.8rem;
    line-height: 1;

    @media screen and (max-width: 1023px) {
        // font-size: 4rem;
        font-size: clamp(32px, 4rem, 80px);
    }
}

p {
    font-weight: 400;
    // font-size: 2.4rem;
    max-width: 35rem;
    line-height: 1.2;

    font-size: clamp(18px, 2.4rem, 40px);

    @media screen and (max-width: 1023px) {
        // font-size: 1.8rem;
        font-size: clamp(16px, 1.8rem, 24px);
        max-width: 24rem;
    }

    &:last-child {
        margin-top: 1.6rem;
    }

    a {
        position: relative;
        text-decoration: none;
        
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: #fff;
            bottom: 0;
            left: 0;
            transform-origin: left;
            transform: scaleX(1);
            transition: transform 1s $ease-out;

            @media screen and (max-width: 1023px) {
                height: 1px;
            }
        }

        &:hover::before {
            transform-origin: right;
            transform: scaleX(0);
        }
    }
}

.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: #02001a;
    opacity: 1;
    transition: opacity 1s $ease-out 0.5s;
    
    &.--hidden {
        pointer-events: none;
        opacity: 0;
    }
}